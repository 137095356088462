import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import FeaturedInformation from './FeaturedInformation'
import { isMobile } from '@cms/utils'

import * as S from './styled'

const decode = require('unescape')

const HomeBanner = ({
  homeBannerData: { image, imageMobile, link, altImage, smallText, highlightText },
  informationData,
}) => {
  const screen = typeof window !== `undefined` ? window.screen : false
  const [mobileDevice, setMobileDevice] = useState(true)

  useEffect(() => {
    if (!isMobile(screen)) {
      setMobileDevice(false)
    }
  }, [screen])

  const bannerImage = mobileDevice ? imageMobile : image

  return (
    <>
      <S.BannerLink href={decode(link)} aria-label={altImage || 'Hero Banner'}>
        <S.Image
          fluid={bannerImage.fluid}
          alt={altImage || 'Hero Banner'}
          loading="eager"
          fetchpriority="high"
          imgStyle={{ objectPosition: 'bottom' }}
        >
          <S.BannerMainContainer>
            <S.BannerTextContainer>
              {smallText && <S.SmallText>{smallText}</S.SmallText>}
              {highlightText && <S.HighlightText>{highlightText}</S.HighlightText>}
            </S.BannerTextContainer>
          </S.BannerMainContainer>
        </S.Image>
      </S.BannerLink>
      <FeaturedInformation informationData={informationData} />
    </>
  )
}

HomeBanner.propTypes = {
  homeBannerData: PropTypes.object.isRequired,
  informationData: PropTypes.object.isRequired,
}

export default HomeBanner
