import styled from 'styled-components'
import media from 'styled-media-query'
import { KeyboardArrowRight } from '@styled-icons/material-rounded'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 4.8rem;

  ${media.lessThan('huge')`
  `}

  ${media.lessThan('medium')`
    margin: 0 0 2.4rem;
  `}
`

export const Title = styled.h2`
  font-size: 4rem;
  font-weight: normal;
  line-height: 4.8rem;
  color: ${props => (props.white ? props.theme.colors.tertiary : props.theme.colors.secondary)};
  margin: 0;

  ${media.lessThan('medium')`
    font-size: 3.2rem;
    line-height: 4rem;
  `}
`

export const ShowAll = styled.a`
  display: flex;
  align-items: center;
  width: fit-content;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: ${props => (props.white ? props.theme.colors.secondary : props.theme.colors.secondary)};

  text-decoration: none;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  ${media.lessThan('medium')`
    opacity: 1;
  `}
`

export const ArrowRightIcon = styled(KeyboardArrowRight)`
  color: ${props => (props.white ? props.theme.colors.secondary : props.theme.colors.secondary)};
  margin-left: 1rem;
  cursor: pointer;
`
