import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const SectionTitle = ({ title, url, label, white }) => {
  return (
    <S.Wrapper>
      <S.Title white={white}>{title}</S.Title>
      <S.ShowAll white={white} href={url}>
        {label}
        <S.ArrowRightIcon white={white} size={26} />
      </S.ShowAll>
    </S.Wrapper>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  white: PropTypes.bool,
}

export default SectionTitle
