import React from 'react'

import * as S from './styled'

const ArrowLeft = ({ differentials }) => (
  <S.ButtonLeftContainer differentials={differentials}>
    <S.Arrow differentials={differentials}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 16L9.41 14.59L3.83 9L16 9L16 7L3.83 7L9.41 1.41L8 0L0 8L8 16Z" fill="#007E8F" />
      </svg>
    </S.Arrow>
  </S.ButtonLeftContainer>
)

export default ArrowLeft
